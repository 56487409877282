import React, { createContext, useContext, useState, useEffect } from 'react';

const Context = createContext();

export const ContextProvider = ({ children }) => {
  const [drawing, setDrawing] = useState([]);
  const [slug, setSlug] = useState('');
  const [id, setID] = useState('');
  const [page, setPage] = useState('Home');
  const [reset, setReset] = useState(false)

  return (
    <Context.Provider value={{drawing, slug, reset, setReset, setSlug, id, setID, page, setPage }}>
      {children}
    </Context.Provider>
  );
};

export const useCustomContext = () => useContext(Context);