import './App.css';

import Home from './pages/Home';
import Share from './pages/Share';
import All from './pages/All';
import Navbar from './components/Navbar';

import './constants/firebase';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ContextProvider } from './state/context'

function App() {
  return (
    <ContextProvider>
      <BrowserRouter>
        <div className="container">

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/:slug" element={<Share />} />
            <Route path="/all" element={<All />} />
            </Routes>
            <footer>
              
              <p style={{marginTop: "1rem", textAlign: "center"}}><a href="https://matthew-gruman.myspreadshop.ca">Buy the shirt</a>.</p>
            </footer>
            </div>
            </BrowserRouter>
            </ContextProvider>
  );
}

export default App;
