import React, { useState, useEffect, useRef } from 'react';

import { useCustomContext } from '../state/context';
import { useParams } from 'react-router-dom';
import { getDatabase, ref, query, orderByChild, equalTo, onValue } from "firebase/database";
function App() {

  const db = getDatabase()
  const { drawing, setPage } = useCustomContext();
  const [slugs, setSlugs] = useState([]);
  useEffect(() => {
    setPage('All')
  }, [])

  useEffect(() => {
    // Your Firebase reference
    const starCountRef = ref(db, 'drawings');
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      let slugs = [];
  
      if (data) {
        // Convert the object into an array using Object.values()
        const dataArray = Object.values(data);
  
        dataArray.map(item => {
          slugs.push(item.slug);
          return null; // React expects a return statement in a map function
        });
  
        setSlugs(slugs);
      }
    });
  }, []);

  return (
    <main>

      <div className="draw-container">
        {
          slugs.map((item, index) => {
            return(
              <a href={"https://pixelg.vercel.app/" + item} id={index}>{item}</a>
            )
          })
        }
      </div>
    </main>
  );
}

export default App;
