import React, { useState, useEffect, useRef } from 'react';
import { useCustomContext } from '../state/context';
import { getDatabase, ref, push, update } from 'firebase/database';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';

import { Link } from 'react-router-dom';
import { FaHeart } from "react-icons/fa";
import { getAuth, signInAnonymously } from "firebase/auth";

let colordata = []
colordata.push('#111');
const newColors = Array.from({ length: 7 }, () =>
  '#' + Math.floor(Math.random() * 16777215).toString(16)
);

colordata.push(...newColors);

function App() {
  const db = getDatabase();
  const { drawing, setReset, page, slug, reset, setSlug, id, setID, setPage } = useCustomContext();
  const [squares, setSquares] = useState(
    Array.from({ length: 64 }, () => ({ state: false, color: '#fff' }))
  );
  const auth = getAuth();
  const [user, setUser] = useState(false)
  const [loading, setLoading] = useState(true);
  const divRef = useRef(null);
  const [divWidth, setDivWidth] = useState(0);
  const [firstClick, setFirstClick] = useState(true);
  const [color, setColor] = useState("#111")
  const containerRef = useRef(null);  
  const textInputRef = useRef(null);
  const [copied, setCopied] = useState(false);
  const [download, setDownload] = useState(false);
  const [random, setRandom] = useState([
    {
      bg: '#111',
      text: '#fff'
    },{
      bg: '#111',
      text: '#fff'
    },{
      bg: '#111',
      text: '#fff'
    }
  ])

  useEffect(() => {
    let bg = getRandomColor();
    let text = getContrastTextColor(bg);
    let bg1 = getRandomColor();
    let text1 = getContrastTextColor(bg1);
    let bg2 = getRandomColor();
    let text2 = getContrastTextColor(bg2);

    const object = [
      {
        bg: bg,
        text:text
      },
      {
        bg: bg1,
        text:text1
      },
      {
        bg: bg2,
        text:text2
      }

    ]
      setRandom(object)
      
    
  }, [])

  useEffect(() => {
    signInAnonymously(auth)
    .then((userCredentials) => {
      // Signed in..
      setUser(userCredentials.user)
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      // ...
      alert("Anonymous login failed. Please try again.")
    });
  }, [])

  const handleCopyClick = () => {
    // Select the text inside the input
    textInputRef.current.select();
    document.execCommand('copy');
    // Deselect the text after copying
    window.getSelection().removeAllRanges();
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 2000)
  };

  function generateRandomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }

    return result;
  }

  

  useEffect(() => {
    setSquares(Array.from({ length: 64 }, () => ({ state: false, color: '#fff' })))
  }, [reset])

  useEffect(() => {
    setPage('Home');
  }, []);

  useEffect(() => {
    const updateWidth = () => {
      if (divRef.current) {
        setDivWidth(divRef.current.clientWidth);
      }
    };

    // Call the function once to set the initial width
    updateWidth();

    // Add a resize event listener to update the width when the window is resized
    window.addEventListener('resize', updateWidth);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []); // Empty dependency array ensures that the effect runs only once on mount

  const handleClick = (index) => {
    const updateIndex = index;
    const currentSquares = [...squares];
    const updatedSquareValue = !currentSquares[updateIndex].state;
    const newSquares = [...currentSquares];
    newSquares[updateIndex] = {
      ...newSquares[updateIndex],
      state: updatedSquareValue,
      color: updatedSquareValue ? color : '#ff', // Toggle between #111 and #ff
    };
    setSquares(newSquares);

    if (firstClick) {
      // make db entry
      setFirstClick(false);
      const newSlug = generateRandomString(4);
      setSlug(newSlug);

      const drawingsRef = ref(db, 'drawings/');

      // Your data
      const newData = {
        drawing: newSquares,
        createdAt: Date.now(),
        slug: newSlug,
        uid: user.uid
      };

      push(drawingsRef, newData).then((newRef) => {
        const insertedId = newRef.key;
        setID(insertedId);
      });
    } else {
      const drawingsRef = ref(db, 'drawings/' + id);

      // Your data
      const newData = {
        drawing: newSquares,
      };

      update(drawingsRef, newData).then(() => {
        // Update successful
      });
    }
  };
  const handleGenerateImage = () => {
    if (containerRef.current) {
      html2canvas(containerRef.current).then((canvas) => {
        // Convert the canvas to a blob
        canvas.toBlob((blob) => {
          // Save the blob as a file using file-saver
          saveAs(blob, 'pixelg.png');
        });
      });
    }
  };

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  function getContrastTextColor(hexColor) {
    // Convert hex color to RGB
    const r = parseInt(hexColor.slice(1, 3), 16);
    const g = parseInt(hexColor.slice(3, 5), 16);
    const b = parseInt(hexColor.slice(5, 7), 16);
  
    // Calculate the relative luminance
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  
    // Use black or white text based on luminance
   return luminance > 0.5 ? '#000000' : '#ffffff'
  }

  

  return (
    <>
     <header>
      <h1><FaHeart size={15} /> <Link to="/">Pixel G</Link></h1>
        <>
        <div className="buttons">
      <button style={{backgroundColor: random[0].bg && random[0].bg, color:random[0].text && random[0].text}} onClick={() => setReset(!reset)}>Reset</button>
      <button  style={{backgroundColor:random[1].bg && random[1].bg, color: random[1].text && random[1].text}} onClick={() => setDownload(!download)}>Lines</button>
      <button  style={{backgroundColor: random[2].bg && random[2].bg, color: random[2].text && random[2].text}} onClick={handleGenerateImage}>Download</button>
      </div>
      <div style={{display: "flex"}}>
      <input type="text" ref={textInputRef} value={copied ? "Copied!" : slug ? "https://pixel.gruman.co/" + slug : "Draw to share"} />
        <span style={{marginTop: "0.3rem"}} onClick={handleCopyClick}>Copy</span>
        </div>
      </>
    </header>
    <main ref={divRef}>
      <div className="color-container">
        {
          colordata.map(item => {
            return(
              <div
              onClick={() => setColor(item)} className={item === color && "color-selected"} style={{
                backgroundColor: item,
                width: divWidth / 8,
                height: divWidth / 8,
              }}>
                </div>
            )
          })
        }
      </div>
      <div className="draw-container" ref={containerRef}>
        {squares.map((square, index) => (
          <div
            key={index}
            onClick={() => handleClick(index)}
            style={{
              backgroundColor: square.state ? square.color : '#fff',
              width: divWidth / 8,
              height: divWidth / 8,
              borderWidth: download && "0px"
            }}
          ></div>
        ))}
      </div>
    </main>
    </>
  );
}

export default App;
